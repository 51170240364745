import { EnvironmentTag } from 'xund-i18n'

export const MATOMO_ACTIVE_ENVIRONMENT = ['DEVELOPMENT', 'BETA', 'LIVE']
export const MATOMO_URL = 'https://xundai.matomo.cloud/'
export const MATOMO_SITE_ID_MAP: Record<EnvironmentTag, Record<string, number>> = {
  DEVELOPMENT: {
    internal: 2,
    client: 4,
  },
  BETA: {
    internal: 5,
    client: 6,
  },
  LIVE: {
    internal: 7,
    client: 8,
  },
  DEMO: {
    internal: 999,
    client: 999,
  },
  MEDICAL_DEVELOPMENT: {
    internal: 999,
    client: 999,
  },
  MEDICAL_RESEARCH: {
    internal: 999,
    client: 999,
  },
  UAT: {
    internal: 999,
    client: 999,
  },
}

export const CATEGORY_SIDE_MENU = 'Side menu'
export const CATEGORY_HEALTH_CHECK = 'Health Check'
export const CATEGORY_SYMPTOM_CHECK = 'Symptom Check'
export const CATEGORY_ILLNESS_CHECK = 'Illness Check'
export const CATEGORY_CHECK_RESTARTED = 'Check restarted'

export const ACTION_REPORT = 'Report'
export const ACTION_BODY_PART_SELECTOR = 'Body part selector'
export const ACTION_ILLNESS_SELECTOR = 'Illness selector'
export const ACTION_SYMPTOM_SELECTOR = 'Symptom selector'
export const ACTION_CHECK_CONTINUATION = 'Check continuation'
